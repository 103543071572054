@import '../../styles/variables';
@import '../../styles/mixin';

.regulation {
  font-size: $text-base;
  position: fixed;
  
  div[class~='modal__content'] {
    padding: 0 !important;
    background: $regulation-modal-background !important;
    box-shadow: $regulation-modal-shadow;
    overflow: unset;
  }

  div[class='modal__body'] {
    position: relative;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    overflow: unset;
    height: fit-content;
    max-height: 80vh;
    padding: 0;

    @include media(lg){
      height: 750px;
    }

    @media screen and (min-width: 1920px) {
      max-height: 954px;
    }
  }

  &__header {
    flex-shrink: 0;
    padding: 1.25rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: $border-radius $border-radius 0px 0px;
    background: $regulation-modal-header-background;
    z-index: 2;

    @media screen and (max-width: 768px) {
      row-gap: 0.5rem;
      padding: 1rem;
    }

    h2 {
      color: $regulation-modal-title-color;
      font-size: $text-xl;
      line-height: $text-2xl;
      letter-spacing: 0.2px;

      @include media(md){
        font-size: $text-4xl;
        line-height: $text-5xl;
        letter-spacing: -0.36px;
      }     
    }

    p {
      color: $regulation-modal-description-color;
      font-size: $text-xs;
      line-height: $text-xl;
      letter-spacing: 0.12px;

      @include media(md){
        line-height: 1.25rem;
        letter-spacing: 0.16px;
      }
    }
  }

  &__body {
    height: 100%;
    display: flex;
    overflow-y: auto;
    padding: 1.25rem 2rem;

    @media screen and (max-width: 768px) {
      padding: 0.75rem;
    }

    &_scroll {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        background: $regulation-modal-scroll-background;

        @include media(md){
          width: 6px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: $regulation-modal-scroll-control;
        border-radius: 8px;
      }
    }
  }

  &__image_desktop {
    position: relative;
    height: 0;
    overflow: hidden;    
    width: 100%;
    padding-top: 78%;

    @media screen and (max-width:767px) {
      display: none;
    }

    img {
      height: auto;
    }
  }

  &__image_mobile {
    position: relative;
    height: 0;
    overflow: hidden;    
    width: 100%;
    padding-top: 628%;

    @media screen and (min-width:768px) {
      display: none;
    }

    img {
      height: auto;
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 96px;
    padding: 1.25rem 2rem;
    justify-content: space-between;
    border-radius: 0px 0px $border-radius $border-radius;
    background: $regulation-modal-header-background;
    z-index: 2;

    @media screen and (max-width: 768px) {
      padding: 1rem;
    }
  }



  &__download_btn {
    padding: 0;

    svg {
      margin-left: 8px;
    }
  } 

  button {
    min-width: 140px;

    @include media(md){
      min-width: 180px;
    }
  }
}
