.berbix__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 420px;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 5rem;
  gap: 1.5rem;

  .berbix_buttons {
    display: flex;
    gap: 1rem;
    width: 100%;

    button {
      margin-top: 1rem;
      flex-grow: 1;
      width: auto;
      text-transform: capitalize;
    }
  }
}
