@import '../../styles/variables';
@import '../../styles/mixin';

.free_delivery_progress {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;

  &__message {
    display: flex;
    align-items: center;
    column-gap: 4px;

    p {
      display: initial !important;
      font-size: $text-sm;
      color: get-value($gray-500, $cart-delivery-message-color);
      font-weight: 400 !important;
      letter-spacing: 0.01px;
      line-height: 20px !important;

      strong {
        color: get-value($primary, $cart-delivery-message-icon-color);
      }

      @include media(sm){
        font-size: $text-base;
      }
    }

    &_completed {
      p {
        color: get-value($gray-dark, $cart-delivery-message-complete-color);

        strong {
          color: inherit;
        }
      }

      svg {
        flex-shrink: 0;
        align-self: baseline;
        width: 20px;
        height: 20px;

        * {
          stroke-width: 2px;
          stroke: get-value($primary, $cart-delivery-message-icon-color);
        }
      }
    }
  }

  .free_delivery_progress {
    flex-grow: 1;

    progress.free_delivery_progress__bar[value] {
      /* Reset the default appearance */
      $alert-border-radius: 6px;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 8px;
      border-radius: $alert-border-radius;

      &::-webkit-progress-bar {
        background-color: get-value($primary-100, $cart-delivery-progress-background);
        border-radius: $alert-border-radius;
      }

      &::-webkit-progress-value {
        background-color: get-value($primary-300, $cart-delivery-progress-color);
        border-radius: $alert-border-radius;
      }
    }

    progress.free_delivery_progress__bar_complete[value] {
      &::-webkit-progress-value {
        background-color: get-value($primary-300, $cart-delivery-progress-complete-color);
        border-radius: $alert-border-radius;
      }
    }
  }
}
