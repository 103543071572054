@import '../../styles/mixin';

.verify__container {
  min-height: 70vh;

  &_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    max-width: 600px;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
    gap: 1.5rem;
  }


  form {
    max-width: 360px;

    @include media(2xl) {
      max-width: 400px;
    }
  }
}
