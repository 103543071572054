@import '../../../styles/variables';
@import '../../../styles/mixin';

.sharing {
  width: fit-content;
  padding: 0.5rem 1rem;
  border: 1px solid $gray-300;
  border-radius: 6px;
  line-height: 1;
  font-size: $text-xl;

  button:not(:last-child) {
    padding-right: 1rem;
    border-right: 1px solid $gray-300;
  }

  button:not(:first-child) {
    padding-left: 1rem;
  }

  > * {
    display: inline-block;
    transition: $transition-all;

    &:hover {
      opacity: $opacity;
    }
  }
}
